<template>
  <div class="app">
    <loading :active.sync="isLoading"></loading>
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="/inicio">
        <img
          class="navbar-brand-full"
          src="../assets/img/Logo-Direct-Cor.png"
          width="128"
          height="43"
          alt="CoreUI Logo"
        />
        <img
          class="navbar-brand-minimized"
          src="img/brand/logo-retraido.png"
          width="30"
          height="30"
          alt="CoreUI Logo"
        />
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <!-- <b-navbar-nav class="d-md-down-none ml-auto">
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdown v-if="false" />
        </b-nav-item>
      </b-navbar-nav> -->
      <b-col display="md">
        Empresa: {{ this.$store.state.login.resale.cnpj | cnpj }} -
        {{ this.$store.state.login.resale.razao_social }}
      </b-col>
      <b-col display="md">
        <b-btn
          ref="breadcrumbButtonFaleconosco"
          variant="info"
          v-on:click="$bvModal.show('modal-faleconosco')"
          class="btn text-white float-right mr-4"
        >
          <i class="fa fa-comments mr-1"></i>
          Fale Conosco
        </b-btn>
      </b-col>

      <b-modal
        id="modal-faleconosco"
        hide-backdrop
        :modal-class="posicaoModal"
        :hide-header="true"
        :hide-footer="true"
      >
        <div class="cabecalho-modal">
          <strong>Nova mensagem</strong>
          <a
            class="float-right btn-fechaModal"
            v-on:click="$bvModal.hide('modal-faleconosco')"
          >
            <i class="fa fa-minus-circle fa-lg" aria-hidden="true"></i>
          </a>
        </div>

        <div class="descricao-campo">
          Olá, por aqui você nos envia sugestões, pontos de melhorias e
          compartilha feedbacks! E se não achou algum produto, nos comunique
          aqui também. Queremos te escutar.
        </div>
        <div class="my-3">
          <textarea
            v-model="mensagem"
            id=""
            cols="70"
            rows="3"
            placeholder="Escreva sua mensagem aqui"
            class="form-control"
          ></textarea>
        </div>
        <div class="d-flex justify-content-center">
          <b-btn variant="" v-on:click="fechaModal" class="btn text-white mr-2">
            Cancelar
          </b-btn>
          <b-btn
            variant="info"
            v-on:click="enviaEmail"
            class="btn text-white"
            :disabled="podeEnviar"
          >
            Enviar
          </b-btn>
        </div>
      </b-modal>
      <!--AsideToggler class="d-none d-lg-block" /-->
    </AppHeader>
    <div class="app-body fundo-tela-inicio">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <div class="row fix-row">
          <div class="col fix-breadcrumb fix-col">
            <Breadcrumb :list="list"></Breadcrumb>
          </div>
          <div
            v-if="breadcrumbButtonVisible"
            class="col-md-3 col-lg-2 mb-3 mb-sm-0 fix-col text-center"
          >
            <b-btn
              ref="breadcrumbButton"
              variant="info"
              v-on:click="breadcrumbButtonGo"
              class="btn-sm text-white fix-btn"
            >
              {{ breadcrumbButtonText }}
            </b-btn>
          </div>
          <div
            v-if="list.length > 3"
            class="col-md-3 col-lg-2 mb-3 mb-sm-0 fix-col text-center"
          >
            <b-btn
              ref="breadcrumbButtonBack"
              variant="info"
              v-on:click="redirectTo(list[list.length - 2].path)"
              class="btn-sm text-white fix-btn"
              :disabled="isD"
            >
              Voltar
            </b-btn>
          </div>
        </div>
        <div
          :class="
            $route.path == '/vendas/clientes' ? 'container' : 'container-fluid'
          "
        >
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside />
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a href="https://dicomp.com.br">Dicomp</a>
        <span class="ml-1">&copy; {{ new Date().getFullYear() }} </span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Sistema de Vendas Intermediadas </span>
        <a href="https://dicomp.com.br">Dicomp</a>
      </div>
    </TheFooter>
  </div>
</template>

<style>
.sidebar .nav-item {
  font-size: 15px;
}

a.nav-link {
  font-size: 14px;
}

.sidebar .nav-link .nav-icon {
  color: #ff7915;
}

.sidebar .nav-link.active .nav-icon {
  color: #fff;
}

.descricao-campo {
  background-color: rgb(238, 238, 238);
  padding: 1% 2%;
  font-size: 10pt;
}

.btn-fechaModal {
  cursor: pointer;
}

.cabecalho-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2% 0;
}

.posicaoModal {
  margin-top: 1%;
  margin-left: 35.1%;
}

@media screen and (max-width: 1600px) {
  .posicaoModal {
    margin-top: 1.5%;
    margin-left: 32%;
  }
}

@media screen and (max-width: 1460px) {
  .posicaoModal {
    margin-top: 1.5%;
    margin-left: 30.5%;
  }
}

@media screen and (max-width: 1420px) {
  .posicaoModal {
    margin-top: 1.5%;
    margin-left: 30%;
  }
}

@media screen and (max-width: 1400px) {
  .posicaoModal {
    margin-top: 1.5%;
    margin-left: 29.5%;
  }
}

@media screen and (max-width: 1380px) {
  .posicaoModal {
    margin-top: 1.5%;
    margin-left: 29%;
  }
}

@media screen and (max-width: 1365px) {
  .posicaoModal {
    margin-top: 1.5%;
    margin-left: 29%;
  }
}

@media screen and (max-width: 1280px) {
  .posicaoModal {
    margin-top: 1.5%;
    margin-left: 27.6%;
  }
}

@media screen and (max-width: 1024px) {
  .posicaoModal {
    margin-top: 2%;
    margin-left: 22%;
  }
}

@media screen and (max-width: 575px) {
  .posicaoModal {
    margin-top: 15%;
    margin-left: 0;
  }
}

.nav-dropdown {
  display: none;
}

.nav-dropdown:has(.nav-item) {
  display: block;
}
</style>

<style scoped>
.fundo-tela-inicio {
  background-image: url("./../assets/img/fundo_tela_inicio.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.sidebar-nav {
  background-color: #5804ca;
}

.sidebar .sidebar-minimizer {
  background-color: #ff7915;
}

@media screen and (max-width: 575px) {
  .fix-btn {
    width: 100%;
    margin-top: 0.55rem;
  }
  .fix-row {
    margin-block-end: 1.3em !important;
  }

  .fix-breadcrumb ol {
    margin-block-end: 0em !important;
    border: 0em !important;
  }
  .fix-col {
    border-radius: 0;
    height: 3rem;
  }
}
@media screen and (min-width: 576px) {
  .fix-row {
    margin-block-end: 1.3em !important;
  }
  .fix-btn {
    width: 90%;
    margin-top: 0.55rem;
    margin-right: 0.55rem;
  }
  .fix-breadcrumb ol {
    margin-block-end: 0em !important;
    border: 0em !important;
  }
  .fix-col {
    border-radius: 0;
    border-bottom: 1px solid #c8ced3;
    height: 3rem;
  }
}

.fix-row {
  background-color: white;
}
</style>

<script>
import nav from "@/_nav";
import { EventBus } from "../helpers/eventBus";
import { httpNoLoading } from "../services/configNoLoading";
import GrupoPermissao from "../services/grupoPermissao";

import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb,
} from "@coreui/vue";
import DefaultAside from "./DefaultAside";
import DefaultHeaderDropdown from "./DefaultHeaderDropdown";

export default {
  name: "DefaultContainer",
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdown,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
  },
  data() {
    return {
      breadcrumbButtonVisible: false,
      breadcrumbButtonText: "",
      breadcrumbButtonUrlPath: "",
      navItems: nav.items,
      mensagem: "",
      posicaoModal: ["posicaoModal"],
      podeEnviar: false,
      isD: true,
      todasPermissoes: [],
      buscouPermissoes: false,
    };
  },
  watch: {
    breadcrumbButtonUrlPath(value) {
      if (value != null && value != "") {
        this.breadcrumbButtonVisible = true;
      } else {
        this.breadcrumbButtonVisible = false;
      }
    },
  },
  methods: {
    onBreadcrumbButtonSet(item) {
      this.breadcrumbButtonUrlPath = item.url;
      this.breadcrumbButtonText = item.text;
    },
    breadcrumbButtonGo() {
      this.redirectTo(this.breadcrumbButtonUrlPath);
    },
    enviaEmail() {
      if (!this.mensagem) {
        return this.$helper.showMsg(
          "O campo mensagem não pode ser enviado vazio, por favor verifique!",
          "warning"
        );
      }
      let dado = {
        mensagem: this.mensagem,
      };
      this.$helper.showMsg(
        "Estamos enviando sua mensagem, aguarde um momento por favor!",
        "info"
      );
      this.podeEnviar = true;
      httpNoLoading
        .post("contato/enviar", dado)
        .then((response) => {
          if (response.status == 200) {
            this.fechaModal();
            return this.$helper.showMsg(
              "Mensagem enviada com sucesso!",
              "success"
            );
          }
        })
        .catch((e) => {
          return this.$helper.showMsg("Erro ao enviar mensagem!", "warning");
        });
    },
    fechaModal() {
      this.mensagem = "";
      this.podeEnviar = false;
      this.$bvModal.hide("modal-faleconosco");
    },
    redirectTo(pathRoute) {
      this.$router.replace(pathRoute);
    },
    getTodasPermissoes() {
      GrupoPermissao.getTodasPermissoes().then((response) => {
        this.todasPermissoes = response.data.data;
        this.buscouPermissoes = true;
      });
    },
  },
  beforeMount() {
    EventBus.$on("onBreadcrumbButtonSet", (item) => {
      this.onBreadcrumbButtonSet(item);
    });

    EventBus.$on("finishLoad", (item) => {
      if (item.finish) {
        this.isD = false;
      } else {
        this.isD = true;
      }
    });
  },
  mounted() {
    this.getTodasPermissoes();
  },
  computed: {
    isLoading: function () {
      if (
        this != null &&
        this.$store != null &&
        this.$store.state != null &&
        this.$store.state.ui != null &&
        this.$store.state.ui.loading != null
      )
        return this.$store.state.ui.loading;
      return false;
    },
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        (route) => route.name || route.meta.label
      );
    },
    nav() {
      if (!this.buscouPermissoes) return;
      this.$store.state.ui.loading = true;
      var menu = this.navItems;

      let menuPermitido = [];
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].children) {
          let subMenus = [];
          for (let j = 0; j < menu[i].children.length; j++) {
            if (menu[i].children[j]) {
              if (
                this.$store.state.login.user.id == 1 &&
                menu[i].children[j].url == "/configuracao/cron"
              ) {
                subMenus.push(menu[i].children[j]);
              } else {
                let permissao = this.todasPermissoes.find(
                  (o) => o.url == menu[i].children[j].url
                );

                if (permissao != undefined && permissao.acessa == 1)
                  subMenus.push(menu[i].children[j]);
              }
            }
          }

          menu[i].children = subMenus;
        }
        menuPermitido.push(menu[i]);
      }

      this.$store.state.ui.loading = false;
      return menuPermitido;
    },
  },
};
</script>
